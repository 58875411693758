import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0a510b2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ensembleAfficheurDataBlock" }
const _hoisted_2 = {
  key: 0,
  class: "partieInterieur"
}
const _hoisted_3 = { class: "partieTexteFullScreen" }
const _hoisted_4 = {
  key: 1,
  class: "partieInterieur"
}
const _hoisted_5 = {
  key: 0,
  class: "partieTexteAVECpieceJointeGauche"
}
const _hoisted_6 = { class: "espaceAffichageImage" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "messageErreur"
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "supportLogoVideoSurImage" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 2,
  class: "partieInterieur"
}
const _hoisted_13 = { class: "espaceAffichageImage" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = {
  key: 1,
  class: "messageErreur"
}
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "supportLogoVideoSurImage" }
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 0,
  class: "partieTexteAVECpieceJointeBasEcran"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.localTexte != '' && _ctx.urlImage == '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.localTexte), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.localTexte != '' && _ctx.urlImage != '' && _ctx.ratio >= 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.localTexte != '' && _ctx.localTexte != ' ' && _ctx.localTexte != '  ' && _ctx.localTexte != '   ')
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.localTexte), 1))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.localTexte != '' && _ctx.localTexte != ' ' && _ctx.localTexte != '  ' && _ctx.localTexte != '   ' ? 'espaceAffichagePieceJointeAvecTexteDroite' : 'espaceAffichagePieceJointeSansTexte')
          }, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, "Loading image..."))
                : _createCommentVNode("", true),
              (_ctx.messageErreur != '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.messageErreur), 1))
                : _createCommentVNode("", true),
              (_ctx.urlImage != '' && !_ctx.loading)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    src: _ctx.urlImage,
                    alt: "Description de l'image",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicAgrandirImage && _ctx.clicAgrandirImage(...args))),
                    class: "image-rounded-corners"
                  }, null, 8, _hoisted_9))
                : _createCommentVNode("", true),
              (this.localDonneeVideo != '')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 3,
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.clicLancerVideo && _ctx.clicLancerVideo(...args))),
                    class: "boutonImage"
                  }, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("img", {
                        src: _ctx.logoPlay,
                        alt: "Bouton play"
                      }, null, 8, _hoisted_11)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2)
        ]))
      : _createCommentVNode("", true),
    (_ctx.localTexte != '' && _ctx.urlImage != '' && _ctx.ratio < 1)
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          _createElementVNode("div", {
            class: _normalizeClass(_ctx.localTexte != '' && _ctx.localTexte != ' ' && _ctx.localTexte != '  ' && _ctx.localTexte != '   ' ? 'espaceAffichagePieceJointeAvecTexteHaut' : 'espaceAffichagePieceJointeSansTexte')
          }, [
            _createElementVNode("div", _hoisted_13, [
              (_ctx.loading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, "Loading image..."))
                : _createCommentVNode("", true),
              (_ctx.messageErreur != '')
                ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(_ctx.messageErreur), 1))
                : _createCommentVNode("", true),
              (_ctx.urlImage != '' && !_ctx.loading)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 2,
                    src: _ctx.urlImage,
                    alt: "Description de l'image",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clicAgrandirImage && _ctx.clicAgrandirImage(...args))),
                    class: "image-rounded-corners"
                  }, null, 8, _hoisted_16))
                : _createCommentVNode("", true),
              (this.localDonneeVideo != '')
                ? (_openBlock(), _createElementBlock("button", {
                    key: 3,
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.clicLancerVideo && _ctx.clicLancerVideo(...args))),
                    class: "boutonImage"
                  }, [
                    _createElementVNode("div", _hoisted_17, [
                      _createElementVNode("img", {
                        src: _ctx.logoPlay,
                        alt: "Bouton play"
                      }, null, 8, _hoisted_18)
                    ])
                  ]))
                : _createCommentVNode("", true)
            ])
          ], 2),
          (_ctx.localTexte != '' && _ctx.localTexte != ' ' && _ctx.localTexte != '  ' && _ctx.localTexte != '   ')
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(_ctx.localTexte), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}