

<template>
  <!-- ensemble -->
  <div v-if="afficherPleinEcran" class="ensembleAffichageImageEnGrand">
    <!-- affichage de l'image -->
    <div :class="legende != '' ? 'espaceAffichageImage' : 'espaceAffichageImageSansLegende'"  @click="clicFermer"> 
        <!-- image -->
        <div v-if="loading">Loading image...</div>
        <div class="messageErreur" v-if="messageErreur != ''">{{ messageErreur }}</div>
        <img v-if="urlImage != '' && !loading" :src="urlImage" alt="Description de l'image"  class="imageclass"  @click="clicFermer">
    </div>

    <div class="espaceAffichageLegende" @click="clicFermer">
      {{ legende }} 
    </div>  

     <!-- bouton fermer le diffuseur video -->
    <button @click="clicFermer" class="boutonFermer">
      <img :src="logoCroixFermer" alt="Bouton fermer"/>
    </button>
    
  </div>

</template>


<script>
import axios from 'axios';
import { watch } from 'vue';
import { InteractionAfficheurImagePleinEcran, retirerImageEnGrand } from '../objetsAafficher/InteractionAfficheurImagePleinEcran';


export default {
props:[],
data() {
    return {
      afficherPleinEcran: false,
      loading: true,
      urlImage: '',
      legende: '',
      logoCroixFermer: require('@/assets/croixfermer.png'),
      messageErreur: ''
    };
  },
  methods:{
    // chargement de l'image
    async loadImage(inUrlImage) {
        // on affiche l'etat en chargement avant de charger l'image
      this.loading = true;
      try {
       
       console.log('Chargement image:', inUrlImage);

        // chargement de l'image
        let response = await axios.get(inUrlImage);

        // on lance l'affichage de l'image lorsque chargee
        this.urlImage = inUrlImage;
      } catch (error) {
        console.error('Error fetching the image URL:', error);
        this.messageErreur = error;
      } finally {
        // on arrete d'afficher l'etat de chargement lorsque image charge
        this.loading = false;

      }
    }, 
    // clic fermer l'afficheur en grand
    clicFermer() {
      // on retire l'affichage de l'image en grand
      retirerImageEnGrand();
    }
  },
  // sur la creation de l'afficheur
  created() {
      // on observe le changement de urlImage dans InteractionAfficheurImagePleinEcran
      watch(() => InteractionAfficheurImagePleinEcran.urlImage, (newValue, oldValue) => {

        // si on a change la valeur de l' urlImage dans InteractionAfficheurImagePleinEcran
        if (newValue !== oldValue) {
          console.log("Modif url image afficheur grand");

          // si il n'y a pas d'url image
            if(newValue == ''){
              // on annule l'affichage de l'image en grand ecran
              this.afficherPleinEcran = false;
              this.urlImage = '';
              this.legende = '';
            }
            // si on a une url image
            else{
              // on init l'afficheur avec l'url de l'image et la legende
              this.afficherPleinEcran = true;
              this.legende = InteractionAfficheurImagePleinEcran.legende;
              this.loadImage(InteractionAfficheurImagePleinEcran.urlImage);
             
          }
        }
  });
    }
}

</script>




<style scoped>


.espaceAffichageImage{
    box-sizing: border-box;
    margin: 0;
    float: left;
    width: 100%;
    height: 90%;
    padding: 1%;
    overflow: hidden;
}

.espaceAffichageImageSansLegende {
    box-sizing: border-box;
    margin: 0;
    float: left;
    width: 100%;
    height: 100%;
    padding: 1%;
    overflow: hidden;
}

.imageclass {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 25px;
}

.espaceAffichageLegende{
  box-sizing: border-box;
  width: 100%;   
  height: 10%;  
  padding: 1%;
  float: left;
  /* couleur texte */
  color: white;
  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* taille texte % de la taille de l'ecran */
  font-size: calc(1vw + 1vh + 1vmin);
}

.ensembleAffichageImageEnGrand {
    box-sizing: border-box;
    width: 100%;   
    height: 100%;
    position: fixed;
    padding: 0;
    float : left; 
    top: 0;
    right: 0;
    margin: 0;
    border-radius: 0px;
    background-color: #2F3E53; /* Color of the rectangle */
}



/**********************
**** BOUTON FERMER ****
**********************/

.boutonFermer {

  width: 10%;  /* 100% of the viewport width */
    height: 8%;  /* 50% of the viewport height */
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: fixed;
    float : left; 
    top: 0;
    right: 0;
    margin: 0;
    border: 0;
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: rgba(255, 255, 255, 0); /* Color of the rectangle */
}

.boutonFermer img {
  max-width: 90%;
  max-height: 90%;
  position: relative;
  float : right; 
  margin-right: 10%;
  margin-top: 10%;
  display: block; /* Prevents inline default spacing */
  object-fit: contain; /* Ensures the image is scaled to be contained within the button without distorting its aspect ratio */
}

</style>