export class Session {
    ID: number;
    idTypeJeux: number;
    idEtablissement : number;
    dateSession : DateObjet;
    heureDebut : Heure;
    heureFin : Heure;
    listContenuJoue : number[];

    constructor(idTypeJeux: number, idEtablissement: number, dateSession: DateObjet, heureDebut: Heure, heureFin: Heure, listContenuJoue: number[]) {
        this.ID = -1;
        this.idTypeJeux = idTypeJeux;
        this.idEtablissement = idEtablissement;
        this.dateSession = dateSession;
        this.heureDebut = heureDebut;
        this.heureFin = heureFin;
        this.listContenuJoue = listContenuJoue;
    }

    // on verifie si la session est expiree
    sessionExpiree(): boolean {
        const now = new Date();
        const year = now.getFullYear();
        const month = now.getMonth() + 1; // getMonth() returns 0-11
        const day = now.getDate();
        const hours = now.getHours();
        const minutes = now.getMinutes();

        // si on a change de date => retourne session expire
        if(this.dateSession.annee != year || this.dateSession.mois != month || this.dateSession.jour != day){
            return true;
        }
        
        const diffMinutes = (hours - this.heureDebut.heures)*60 + minutes - this.heureDebut.minutes;

        // si la session a depasse les 2 heures (120 minutes) => retourne session expire
        if(diffMinutes > 120) {
            return true;
        }

        // si pas de depassement => retourne la session n'est pas expire
        return false;
    }
    // on met a jour l'heure de fin de la session
    majHeureFinEtAjourContenusJoue(inIDQuestion : number): void {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const secondes = now.getSeconds();
        // maj heure fin
        this.heureFin = new Heure(secondes, minutes, hours);
        // maj list contenus deja joue
        this.listContenuJoue.push(inIDQuestion);
    }
}

export class DateObjet {
    jour: number;
    mois : number;
    annee : number; 

    constructor(jour: number, mois : number, annee : number) {
        this.jour = jour;
        this.mois = mois;
        this.annee = annee;
    }

}

export class Heure {
    secondes: number;
    minutes: number;
    heures : number;

    constructor(secondes: number, minutes: number, heures : number) {
        this.secondes = secondes;
        this.minutes = minutes;
        this.heures = heures;
    }
}

export function initSession(inIdModule : number, inIdEtablissement : number) {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // getMonth() returns 0-11
    const day = now.getDate();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const secondes = now.getSeconds();

    // date 
    const dateAujoudhui = new DateObjet(day, month, year);
    // heure
    const heureMaintenant= new Heure(secondes, minutes, hours);
    // init session
    const sessionActuelle = new Session(inIdModule, inIdEtablissement, dateAujoudhui, heureMaintenant, heureMaintenant, []);

    // on retourne la session
    return sessionActuelle;
}

