// store.js
import { reactive } from 'vue';

export const InteractionAfficheurKaraoke = reactive({
  paroleKaraoke: '' as any, 
  tempsDefilementSon: -1 as number
});


export function stopKaraoke() {
    InteractionAfficheurKaraoke.tempsDefilementSon = -1;
}

export function lancerKaraoke(inParoleKaraoke : any, inTempsDefilementSon : number) {
    InteractionAfficheurKaraoke.paroleKaraoke = inParoleKaraoke;
    InteractionAfficheurKaraoke.tempsDefilementSon = inTempsDefilementSon;
}