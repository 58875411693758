// store.js
import { reactive } from 'vue';

export const InteractionPopup = reactive({
  titrePopup: '' as string, 
  textePopup: '' as string, 
  nomBouton1: '' as string, 
  fonctionBouton1: {} as any,
  nomBouton2: '' as string, 
  fonctionBouton2: {} as any,
  nomBouton3: '' as string, 
  fonctionBouton3: {} as any
});


export function ouvrirPopup1Bouton(newValue : string, inNomBouton1 : string, inFunctionSurBouton1 : any) {
  // bouton 1
    InteractionPopup.nomBouton1 = inNomBouton1;
    InteractionPopup.fonctionBouton1 = inFunctionSurBouton1;
    // bouton 2 et 3 pas present
    InteractionPopup.nomBouton2 = '';
    InteractionPopup.nomBouton3 = '';
    // texte popup
    InteractionPopup.titrePopup = '';
    InteractionPopup.textePopup = newValue;
}


export function ouvrirPopup2Bouton(newValue : string, inNomBouton1 : string, inFunctionSurBouton1 : any, inNomBouton2 : string, inFunctionSurBouton2 : any) {
  // bouton 1
  InteractionPopup.nomBouton1 = inNomBouton1;
  InteractionPopup.fonctionBouton1 = inFunctionSurBouton1;
  // bouton 2
  InteractionPopup.nomBouton2 = inNomBouton2;
  InteractionPopup.fonctionBouton2 = inFunctionSurBouton2;
  // bouton 3 inactif
  InteractionPopup.nomBouton3 = '';
  // texte popup
  InteractionPopup.titrePopup = '';
  InteractionPopup.textePopup = newValue;
}

export function ouvrirPopup2texte2Bouton(inTitrePopup : string, newValue : string, inNomBouton1 : string, inFunctionSurBouton1 : any, inNomBouton2 : string, inFunctionSurBouton2 : any) {
  
  // bouton 1
  InteractionPopup.nomBouton1 = inNomBouton1;
  InteractionPopup.fonctionBouton1 = inFunctionSurBouton1;
  // bouton 2
  InteractionPopup.nomBouton2 = inNomBouton2;
  InteractionPopup.fonctionBouton2 = inFunctionSurBouton2;
  // bouton 3 inactif
  InteractionPopup.nomBouton3 = '';
  // texte popup
  InteractionPopup.titrePopup = inTitrePopup;
  InteractionPopup.textePopup = newValue;
}



export function ouvrirPopup3Bouton(newValue : string, inNomBouton1 : string, inFunctionSurBouton1 : any, inNomBouton2 : string, inFunctionSurBouton2 : any, inNomBouton3 : string, inFunctionSurBouton3 : any) {
  // bouton 1
  InteractionPopup.nomBouton1 = inNomBouton1;
  InteractionPopup.fonctionBouton1 = inFunctionSurBouton1;
  // bouton 2
  InteractionPopup.nomBouton2 = inNomBouton2;
  InteractionPopup.fonctionBouton2 = inFunctionSurBouton2;
  // bouton 3 
  InteractionPopup.nomBouton3 = inNomBouton3;
  InteractionPopup.fonctionBouton3 = inFunctionSurBouton3;
  // texte popup
  InteractionPopup.titrePopup = '';
  InteractionPopup.textePopup = newValue;
}

// You can also define a method to change the value
export function fermerPopup() {
  InteractionPopup.nomBouton1 = '';
  InteractionPopup.fonctionBouton1 = '';
  InteractionPopup.titrePopup = '';
  InteractionPopup.textePopup = '';
  InteractionPopup.nomBouton2 = '';
  InteractionPopup.fonctionBouton2 = '';
  InteractionPopup.nomBouton3 = '';
  InteractionPopup.fonctionBouton3='';
}

