<template>
    
    <div class="ensembleAfficheurDataBlock">
        <!-- affiche si il n'y a que du texte -->
        <div v-if="localTexte != '' && urlImage == ''" class="partieInterieur">
            <div class="partieTexteFullScreen">
                {{ localTexte }}
            </div>
        </div>

        <!-- affiche si il y a une image (image ou preview d'une video) ET que l'image est en portrait -->
        <div v-if="localTexte != '' && urlImage != '' && ratio >= 1" class="partieInterieur">
            <!-- affiche de la partie texte a gauche de l'ecran -->
            <div v-if="localTexte != '' && localTexte != ' ' && localTexte != '  ' && localTexte != '   '"  class="partieTexteAVECpieceJointeGauche">
                {{ localTexte }}
            </div>


            <!-- affiche de l'image a droite de l'ecran -->
            <div :class="localTexte != '' && localTexte != ' ' && localTexte != '  ' && localTexte != '   ' ? 'espaceAffichagePieceJointeAvecTexteDroite' : 'espaceAffichagePieceJointeSansTexte'"> 
                <div class="espaceAffichageImage"> 
                    <!-- image -->
                    <div v-if="loading">Loading image...</div>
                    <div class="messageErreur" v-if="messageErreur != ''">{{ messageErreur }}</div>
                    <img v-if="urlImage != '' && !loading" :src="urlImage" alt="Description de l'image" @click="clicAgrandirImage"  class="image-rounded-corners">
                    <!-- symbole video cliquable si video a lancer -->
                    <button v-if="this.localDonneeVideo != ''" @click="clicLancerVideo" class="boutonImage">
                        <div class="supportLogoVideoSurImage">
                            <img :src="logoPlay" alt="Bouton play"/>
                        </div>
                    </button>
                </div>
            </div>
            
            
        </div>

         <!-- affiche si il y a une image (image ou preview d'une video) ET que l'image est en paysage -->
        <div v-if="localTexte != '' && urlImage != '' && ratio < 1" class="partieInterieur">
            <!-- affiche de l'image en haut de l'ecran -->
            <div :class="localTexte != '' && localTexte != ' ' && localTexte != '  ' && localTexte != '   ' ? 'espaceAffichagePieceJointeAvecTexteHaut' : 'espaceAffichagePieceJointeSansTexte'"> 
                <div class="espaceAffichageImage"> 
                    <!-- image -->
                    <div v-if="loading">Loading image...</div>
                    <div class="messageErreur" v-if="messageErreur != ''">{{ messageErreur }}</div>
                    <img v-if="urlImage != '' && !loading" :src="urlImage" alt="Description de l'image" @click="clicAgrandirImage"  class="image-rounded-corners">
                    <!-- symbole video cliquable si video a lancer -->
                    <button v-if="this.localDonneeVideo != ''" @click="clicLancerVideo" class="boutonImage">
                        <div class="supportLogoVideoSurImage">
                            <img :src="logoPlay" alt="Bouton play"/>
                        </div>
                    </button>
                </div>
            </div>
            
            <!-- affiche de la partie texte au milieu de l'ecran en dessous de l'image -->
            <div v-if="localTexte != '' && localTexte != ' ' && localTexte != '  ' && localTexte != '   '"  class="partieTexteAVECpieceJointeBasEcran">
                {{ localTexte }}
            </div>
            
        </div>
    </div>
        

</template>


<script lang="ts">
import { defineComponent  } from 'vue';
import axios from 'axios';
import { ConstServeur, InfoServeur } from '../objetsTypeScript/InfoServeur';
import { lectureObjetBDDVsID } from '../fonction/fonctionServeur';
import { useScreenOrientation  } from '../fonction/screenOrientation';
import { InteractionDiffuseurVideo } from '../objetsAafficher/InteractionDiffuseurVideo';
import { InteractionAfficheurImagePleinEcran } from '../objetsAafficher/InteractionAfficheurImagePleinEcran';

export default defineComponent ({
props:['localDataBlock', 'isLegendeSurAgrandissementImage'],
components: {
    
  },
  setup() {
    const { orientation, ratio } = useScreenOrientation();

    return { orientation, ratio };
  },
data() {
    return {
      localTexte: '' as string,
      urlImage: '' as string, 
      //hauteurImage: 0 as number,
      //largeurImage: 0 as number,
      //ratioScreen: 0 as any,
      localDonneeImage: '' as any,
      localDonneeVideo: '' as any,
      loading: false,
      logoPlay: require('@/assets/playVideo.png'),
      messageErreur: '' as any
    };
  },
  
methods:{
    // chargement de l'image
    async loadImage(inUrlImage : string) {
        //this.largeurImage = 0;
        //this.hauteurImage = 0;
        // on affiche l'etat en chargement avant de charger l'image
        this.loading = true;
        try {
        
        console.log('Chargement image:', inUrlImage);

            // chargement de l'image
            let response = await axios.get(inUrlImage);

            // on recupere la largeur et la hauteur de l'image
            /*const image = new Image();

            image.onload = () => {
                console.log('Width:', image.width);
                console.log('Height:', image.height);
                this.largeurImage = image.width;
                this.hauteurImage = image.height;
            };

            image.src = inUrlImage;*/

            //const { orientation, ratio } = useScreenOrientation();

            //this.ratioScreen = ratio;

            // on lance l'affichage de l'image lorsque chargee
            this.urlImage = inUrlImage;
        } catch (error) {
            console.error('Error fetching the image URL:', error);
            this.messageErreur = error;
        } finally {
            // on arrete d'afficher l'etat de chargement lorsque image charge
            this.loading = false;

        }
    }, 
    // chargement de la donnee image
    chargementDonneeImage(inIdDonneeImage : number){
        //console.log("Chargement donnee image : "+inIdDonneeImage.toString());
        // chargement de la donnee image dans la BDD
        lectureObjetBDDVsID(inIdDonneeImage.toString(), ConstServeur.NOM_TABLE_IMAGE_BIBLI).then(data => {
                    // si on a reussi a recuperer une donnee dans la BDD
                    if(data != ''){
                        // on recupere la donnee a partir du json
                        this.localDonneeImage = JSON.parse(data);
                        // on lance le chargement de l'image
                        this.loadImage(InfoServeur.urlServeur + InfoServeur.repertoireImageBibli + this.localDonneeImage.nomServeur);
                    }
                    // si on n'a pas reussi a recupere la donnee dans la BDD
                    else{
                        // on efface la donnee image => on affiche pas d'image
                        this.localDonneeImage = '';
                        console.log("Impossible de charger "+inIdDonneeImage.toString()+" dans bibliotheque_image");
                    }
                });
    },
    // si on clique sur l'image de preview de la video => on lance la video
    clicLancerVideo(){
        //console.log("Lancer video : "+this.localDonneeVideo.legende);

        // on envoi la donnee video au diffuseur video pour lire la video
        InteractionDiffuseurVideo.donneeVideo = this.localDonneeVideo;
        
    },
    // si on clique sur l'image (sans video) => on affiche l'image en grand
    clicAgrandirImage(){
        // si on a une image bibliotheque => on recupere la legende
        if(this.localDonneeImage != '' && this.isLegendeSurAgrandissementImage){
            InteractionAfficheurImagePleinEcran.legende = this.localDonneeImage.legende;   
        }
        else{
            InteractionAfficheurImagePleinEcran.legende = '';
        }
        // on modif l'url de l'image dans InteractionAfficheurImagePleinEcran pour afficher l'image en plein ecran
        InteractionAfficheurImagePleinEcran.urlImage = this.urlImage;
    }
},
// lorsque l'afficheur datablock est cree
created() {
        //console.log("Creation datablock : "+this.localDataBlock.texte);

        // on modifie le texte affichee avec le texte de datablock
        this.localTexte = this.localDataBlock.texte;
        
        // si on a une video => on charge l'image preview de la video
        if(this.localDataBlock.idDonneeVideo != -1) {
            //console.log("Chargement donnee video : "+this.localDataBlock.idDonneeVideo.toString());
            // on lance la lecture de la donnee video dans la BDD
            lectureObjetBDDVsID(this.localDataBlock.idDonneeVideo.toString(), ConstServeur.NOM_TABLE_VIDEO_BIBLI).then(data => {
                    // si on a reussi a recuperer une donnee video dans la BDD
                    if(data != ''){
                        // on recupere la donnee video a partir du json
                        this.localDonneeVideo = JSON.parse(data);

                        // si on a un extrait sur la video => on affiche l'image preview
                        if(this.localDonneeVideo != null && this.localDonneeVideo.listExtrait.length > 0) {
                            //console.log("Affichage image extrait 0 de : "+this.localDonneeVideo.legende);
                            // si l'image preview et dans la bilbiotheque image
                            if (this.localDonneeVideo.listExtrait[0].idBibliImage != -1) {
                                // on affiche l'image de la bilbitheque
                                this.chargementDonneeImage(this.localDonneeVideo.listExtrait[0].idBibliImage);
                            }
                            // si l'image preview et directement dans la donnee video
                            else if (this.localDonneeVideo.listExtrait[0].lienServeurApercu != '') {
                                // on affiche l'image a partir du dossier bibliotheque video
                                this.loadImage(InfoServeur.urlServeur+InfoServeur.repertoireVideoBibli+this.localDonneeVideo.listExtrait[0].lienServeurApercu);
                            }
                            // si on n'a pas d'image preview
                            else {
                                // => on retire la video
                                this.localDonneeVideo = '';
                            }
                        }
                        // si on n'a pas d'extrait sur la video
                        else{
                            // => on retire la video
                            this.localDonneeVideo = '';
                        }
                    }
                    // si on n'arrive pas a charge la donnee video
                    else{
                        // => on retire la video
                        this.localDonneeVideo = '';
                        console.log("Impossible de charger "+this.localDataBlock.idDonneeVideo.toString()+" dans bibliotheque_video");
                    }
            });
        }
        // si on n'a pas de video => on affiche image et son
        else {
            if(this.localDataBlock.idDonneeImage != -1) {
                this.chargementDonneeImage(this.localDataBlock.idDonneeImage);
            }
            // si on a une image sur la question
            else if(this.localDataBlock.cheminImage != '') {
                // on charge l'image
                this.loadImage(InfoServeur.urlServeur+InfoServeur.repertoireImage+this.localDataBlock.cheminImage);
            }


            // le lancement du son a ete deplace dans le script question 
            // => car il faut lancer le son a chaque fois qu'on affiche le block pas seulement dans on le cree
        }

        
    }

});


</script>




<style scoped>

/*************************
*** ENSEMBLE DATABLOCK ***
**************************/

.ensembleAfficheurDataBlock{
    box-sizing: border-box;
    width: 100%;   
    height: 100%;
    padding: 0;
    position: relative;
    margin: 0;
    background-color: rgba(255, 255, 255, 0); /* Color of the rectangle */
    border-radius: 0px;
}
.partieInterieur {
    box-sizing: border-box;
    width: 100%;   
    height: 100%;  
    padding: 0;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* taille texte 4% de la taille de l'ecran */
    font-size: calc(2vw + 2vh + 2vmin);
}

.partieTexteFullScreen {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 100%;  /* 50% of the viewport height */
    padding: 2%;
    float: left;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;

}

/***********************
*** TEXTE AVEC IMAGE ***
************************/

.partieTexteAVECpieceJointeGauche {
    box-sizing: border-box;
    width: 70%;   /* 100% of the viewport width */
    height: 100%;  /* 50% of the viewport height */
    padding: 5%;
    float: left;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(1.5vw + 1.5vh + 1.5vmin);
}

.partieTexteAVECpieceJointeBasEcran {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 65%;  /* 50% of the viewport height */
    padding: 5%;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(1.5vw + 1.5vh + 1.5vmin);
}

/**********************
*** AFFICHAGE IMAGE ***
**********************/

.espaceAffichagePieceJointeAvecTexteDroite {
    box-sizing: border-box;
    width: 30%;
    height: 100%; 
    padding: 0;
    position: relative;
    float : left; 
}

.espaceAffichagePieceJointeSansTexte {
    box-sizing: border-box;
    width: 100%;  
    height: 100%;
    padding: 1%;
    /* centre le texte verticalement */
    position: relative;
    float : left; 
    align-items: center;
    justify-content: center;
}

.espaceAffichagePieceJointeAvecTexteHaut {
    box-sizing: border-box;
    width: 100%;
    height: 35%; 
    padding: 0;
    /* centre le texte verticalement */
    position: relative;
    float : left; 
    align-items: center;
    justify-content: center;
}

.espaceAffichageImage{
    box-sizing: border-box;
    margin: 0;
    float: left;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.espaceAffichageImage img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.espaceAffichageImage img.image-rounded-corners {
border-radius: 25px;
}


/********************************
*** BOUTON SUR IMAGE SI VIDEO ***
*********************************/

.boutonImage {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 100%;  /* 50% of the viewport height */
    padding: 0;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    position: absolute;
    float : left; 
    top: 0;
    left: 0;
    margin: 0;
    align-items: center;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0);
    border-width: 0;
}

.supportLogoVideoSurImage{
    box-sizing: border-box;
    width: 20%;   /* 100% of the viewport width */
    height: 20%;  /* 50% of the viewport height */
    padding: 0;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    position: absolute;
    float : left; 
    top: 40%;
    left: 40%;
    margin: 0;
    align-items: center;
    justify-content: center;
    margin: 0;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0);
    border-width: 0;
}

.boutonImage img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block; 
}

</style>