import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-175cdc6c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "fullscreen-div" }
const _hoisted_2 = {
  key: 0,
  class: "partieChargementInitial"
}
const _hoisted_3 = { class: "partieQuestion" }
const _hoisted_4 = {
  key: 1,
  class: "partieReponse"
}
const _hoisted_5 = {
  key: 0,
  class: "blocReponse"
}
const _hoisted_6 = { class: "fondBlocReponse" }
const _hoisted_7 = {
  key: 1,
  class: "blocReponse"
}
const _hoisted_8 = { class: "fondBlocReponse" }
const _hoisted_9 = {
  key: 2,
  class: "blocReponse"
}
const _hoisted_10 = { class: "fondBlocReponse" }
const _hoisted_11 = {
  key: 3,
  class: "blocReponse"
}
const _hoisted_12 = { class: "fondBlocReponse" }
const _hoisted_13 = {
  key: 2,
  class: "partieBoutonPendantQuestion"
}
const _hoisted_14 = { class: "conteneurBoutonFooterCentral" }
const _hoisted_15 = {
  key: 3,
  class: "partieBoutonApresQuestion"
}
const _hoisted_16 = { class: "texteMessageApresReponse" }
const _hoisted_17 = {
  key: 4,
  class: "partieAnecdote"
}
const _hoisted_18 = { class: "partieBlockAnecdote" }
const _hoisted_19 = { class: "partieBoutonPendantQuestion" }
const _hoisted_20 = { class: "conteneurBoutonFooterCentral" }
const _hoisted_21 = {
  key: 5,
  class: "partieIndice"
}
const _hoisted_22 = { class: "partieExterieurBlockIndice" }
const _hoisted_23 = { class: "partieBlockIndice" }
const _hoisted_24 = { class: "partieInterieurIndice" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AfficheurDataBlock = _resolveComponent("AfficheurDataBlock")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.localQuestion == '')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, " popup chargement "))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_AfficheurDataBlock, {
        localDataBlock: this.localQuestion,
        isLegendeSurAgrandissementImage: false
      }, null, 8, ["localDataBlock"])
    ]),
    (_ctx.localReponse.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_ctx.localReponse.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.classReponseAafficher(0)),
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (!this.reponseEliminerParJoker5050.includes(0) && !this.reponseValidee  ? _ctx.clicReponse(0) : null))
                  }, _toDisplayString(_ctx.localReponse[0]), 3)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.localReponse.length > 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.classReponseAafficher(1)),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (!this.reponseEliminerParJoker5050.includes(1) && !this.reponseValidee  ? _ctx.clicReponse(1) : null))
                  }, _toDisplayString(_ctx.localReponse[1]), 3)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.localReponse.length > 2)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.classReponseAafficher(2)),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (!this.reponseEliminerParJoker5050.includes(2) && !this.reponseValidee  ? _ctx.clicReponse(2) : null))
                  }, _toDisplayString(_ctx.localReponse[2]), 3)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.localReponse.length > 3)
            ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("div", {
                    class: _normalizeClass(_ctx.classReponseAafficher(3)),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (!this.reponseEliminerParJoker5050.includes(3) && !this.reponseValidee  ? _ctx.clicReponse(3) : null))
                  }, _toDisplayString(_ctx.localReponse[3]), 3)
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (!_ctx.reponseValidee)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            (_ctx.localReponse.length > 2 && !_ctx.joker5050utilise)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "boutonJoker5050",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.clicJoker5050()))
                }, " Joker 50/50 "))
              : _createCommentVNode("", true)
          ]),
          (_ctx.localIndice != '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "boutonIndice",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.clicIndice()))
              }, " Indice "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.reponseValidee)
      ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.messageApresReponse), 1),
          _createElementVNode("div", {
            class: "boutonPasserAlanecdote",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.clicPasserAlanecdote()))
          }, " Suivant ")
        ]))
      : _createCommentVNode("", true),
    (_ctx.voirAnecdote)
      ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createVNode(_component_AfficheurDataBlock, {
              localDataBlock: _ctx.localAnecdote,
              isLegendeSurAgrandissementImage: true
            }, null, 8, ["localDataBlock"])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createElementVNode("div", {
                class: "boutonJoker5050",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.clicRetourALaQuestion()))
              }, " Retour ")
            ]),
            _createElementVNode("div", {
              class: "boutonQuestionSuivant",
              onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.clicChargerQuestionSuivante()))
            }, " Suivant ")
          ])
        ]))
      : _createCommentVNode("", true),
    (_ctx.voirIndice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _createVNode(_component_AfficheurDataBlock, {
                  localDataBlock: _ctx.localIndice,
                  isLegendeSurAgrandissementImage: false
                }, null, 8, ["localDataBlock"])
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "boutonRetourApresIndice",
            onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.clicFermerIndice()))
          }, " OK ")
        ]))
      : _createCommentVNode("", true)
  ]))
}