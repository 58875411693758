

<template>
  <!-- ensemble diffuseur video -->
  <div v-if="videoUrl != ''" class="ensembleDiffuseurVideo">
    <!-- affichage de la video -->
    <div class="espaceAffichageVideo"> 
      <video ref="videoPlayer" @timeupdate="updateTime" @ended="resetTime">
      <source :src="this.videoUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>             
    </div>

    <!-- controleurs de la video -->
    <div class="espaceAffichageBouton"> 
      <!-- bouton play -->
      <button v-if="this.$refs.videoPlayer && !isVideoPlaying()" @click="play" class="boutonImage">
        <img :src="logoPlay" alt="Bouton play"/>
      </button>
       <!-- bouton pause -->
      <button v-if="this.$refs.videoPlayer != null && isVideoPlaying()" @click="pause" class="boutonImage">
        <img :src="logoPause" alt="Bouton pause"/>
      </button>
       <!-- bouton stop -->
      <button @click="stop" class="boutonImage">
        <img :src="logoStop" alt="Bouton stop"/>
      </button>
       <!-- temps restant -->
      <div class="espaceAffichageTempsRestant"> 
        {{ remainingTime }}
      </div>   
    </div>

     <!-- bouton fermer le diffuseur video -->
    <button @click="clicFermer" class="boutonFermerVideo">
      <img :src="logoCroixFermer" alt="Bouton fermer"/>
    </button>
    
  </div>

</template>


<script>
import { InfoServeur } from '../objetsTypeScript/InfoServeur';
import { watch } from 'vue';
import { InteractionDiffuseurVideo } from '../objetsAafficher/InteractionDiffuseurVideo';
import { stopSon } from '../objetsAafficher/InteractionDiffuseurSon';

export default {
props:[],
data() {
    return {
      videoUrl: '',
      localDonneeVideo: '',
      logoPlay: require('@/assets/play.png'),
      logoPause: require('@/assets/pause.png'),
      logoStop: require('@/assets/stop.png'),
      logoCroixFermer: require('@/assets/croixfermer.png'),
      interval: null,
      remainingTime: 0
    };
  },
  methods:{
    // check si la video est en train d'etre joue
    isVideoPlaying() {
      const videoPlayer = this.$refs.videoPlayer;
      // la video est en train d'etre joue si elle est ni en pause ni termine
      return videoPlayer && !videoPlayer.paused && !videoPlayer.ended;
    },
    // clic play
    play() {
      if(this.$refs.videoPlayer != null){
        this.$nextTick(() => {
          if (this.$refs.videoPlayer) {
          this.$refs.videoPlayer.play();
          }
        });
      }
    },
    // clic pause
    pause() {
      if(this.$refs.videoPlayer != null){
        this.$refs.videoPlayer.pause();
      }
    },
    // clic stop
    stop() {
      if(this.$refs.videoPlayer != null){
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.currentTime = 0;
        this.remainingTime = this.totalDuration.toFixed(2);
      }
    },
    // clic fermer diffuseur video
    clicFermer() {
      InteractionDiffuseurVideo.donneeVideo = null;
    },
    avanceCinqSecond() {
      this.$refs.videoPlayer.currentTime += 5;
    },
    reculeCinqSecond() {
      this.$refs.videoPlayer.currentTime -= 5;
    },
    // fonction en boucle lorsque la video et en train d'etre jouee
    updateTime() {
      if(this.$refs.videoPlayer != null){
        // on recupere le temps total de la video
        this.totalDuration = this.$refs.videoPlayer.duration;
        // on affiche le temps restant = difference en temps total et temps ecoule
        this.remainingTime = (this.totalDuration - this.$refs.videoPlayer.currentTime).toFixed(2);
      }
    },
    // on reset le temps restant affiche
    resetTime() {
      if(this.$refs.videoPlayer != null){
        // on affiche le temps restant = temps total de la video
        this.remainingTime = this.totalDuration.toFixed(2);
      }
    }
  },
  mounted() {
    if(this.$refs.videoPlayer != null){
      this.$refs.videoPlayer.addEventListener('loadedmetadata', () => {
      this.totalDuration = this.$refs.videoPlayer.duration;
      this.remainingTime = this.totalDuration.toFixed(2);
    });
    }
    
  },
  // sur la creation du diffuseur video
  created() {
      // on observe le changement de donneeVideo dans InteractionDiffuseurVideo
      watch(() => InteractionDiffuseurVideo.donneeVideo, (newValue, oldValue) => {

        // si on a change la valeur de la donnee video dans InteractionDiffuseurVideo
        if (newValue !== oldValue) {
          console.log("Modif donnee video diffuseur video");
          // on stop le son du diffuseur son
          stopSon();

          // si pas de data block
            if(newValue == null){
              // stop la video actuelle
              this.stop();
              // on annule le lancement de la video
              this.localDonneeVideo = '';
              this.videoUrl = '';
            }
            // si donnee video => on lance le video
            else{
              // on stop le video d'avant
              this.stop();

              // on init le diffuseur video avec la nouvelle donnee video
              this.localDonneeVideo = InteractionDiffuseurVideo.donneeVideo;

              console.log(JSON.stringify(this.localDonneeVideo));

              // on met l'url de la video
              this.videoUrl = InfoServeur.urlServeur + InfoServeur.repertoireVideoBibli + this.localDonneeVideo.listExtrait[0].lienServeurVideo;
          
              // on lance la video direct
              this.$nextTick(() => {
              if (this.$refs.videoPlayer) {
                this.$refs.videoPlayer.play();
              }
              });
          }
        }
  });
    }
}

</script>




<style scoped>


.ensembleDiffuseurVideo {
    box-sizing: border-box;
    width: 100%;   
    height: 100%;
    position: fixed;
    padding: 0;
    float : left; 
    top: 0;
    right: 0;
    margin: 0;
    background: whitesmoke;
    border-radius: 0px;
    background-color: #2F3E53; /* Color of the rectangle */
}


/******************************
**** AFFICHAGE DE LA VIDEO ****
*******************************/

.espaceAffichageVideo {
  box-sizing: border-box;
    width: 100%;   
    height: 90%;
    padding: 2%;
    position: relative;
    
    margin: 0;
    background: whitesmoke;
    border-radius: 0px;
    background-color: #2F3E53; /* Color of the rectangle */
    color: white;

    overflow: hidden;

}

.espaceAffichageVideo video {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/******************************
**** AFFICHAGE DES BOUTONS ****
*******************************/

.espaceAffichageBouton {
  box-sizing: border-box;
  
  width: 40%;  
  margin-left : 30%;

  height: 10%; 
  padding: 0.5%;
  /* met le bloc a la suite du bloc precedant */
  position: relative;
  float : center; 
  border-radius: 20px;
  background-color: #2F3E53 ;
  color: white;
}

.boutonImage {
  box-sizing: border-box;
  margin: 0;
  float: left;
  width: 30%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
  border-width: 0;
}

.boutonImage img {
  max-width: 100%;
  max-height: 100%;
  display: block; /* Prevents inline default spacing */
  object-fit: contain; /* Ensures the image is scaled to be contained within the button without distorting its aspect ratio */
}

/********************************
**** AFFICHAGE TEMPS RESTANT ****
********************************/

.espaceAffichageTempsRestant {
  box-sizing: border-box;
  width: 40%;  
  height: 100%; 
  padding: 2%;
  /* met le bloc a la suite du bloc precedant */
  position: relative;
  float : left; 
  top: 0;
  left: 0;
  margin: 0;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;
  /* taille texte 3% de la taille de l'ecran */
    font-size: calc(1vw + 1vh + 1vmin);
}

/********************************
**** BOUTON FERMER DIFFUSEUR ****
********************************/

.boutonFermerVideo {

  width: 10%;  /* 100% of the viewport width */
    height: 8%;  /* 50% of the viewport height */
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: fixed;
    float : left; 
    top: 0;
    right: 0;
    margin: 0;
    border: 0;
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: rgba(255, 255, 255, 0); /* Color of the rectangle */
}

.boutonFermerVideo img {
  max-width: 90%;
  max-height: 90%;
  position: relative;
  float : right; 
  margin-right: 10%;
  margin-top: 10%;
  display: block; /* Prevents inline default spacing */
  object-fit: contain; /* Ensures the image is scaled to be contained within the button without distorting its aspect ratio */
}

</style>