<template>
<div class="fullscreen-div">

        <!-- QUESTION -->
        <div v-if="localQuestion == ''" class="partieChargementInitial">
            popup chargement
        </div>
        <div class="partieQuestion">
            <AfficheurDataBlock :localDataBlock="this.localQuestion" :isLegendeSurAgrandissementImage="false"/>
         </div>

         <!-- REPONSE -->
        <div v-if="localReponse.length > 0" class="partieReponse">
            <!-- REPONSE 1 -->
            <div v-if="localReponse.length > 0" class="blocReponse">
                <div class="fondBlocReponse">
                    <!-- si reponse desactive par joker 5050 -->
                    <div :class="classReponseAafficher(0)" @click="!this.reponseEliminerParJoker5050.includes(0) && !this.reponseValidee  ? clicReponse(0) : null">
                        {{ localReponse[0] }}
                    </div>
                </div>
            </div>
            <!-- REPONSE 2 -->
            <div v-if="localReponse.length > 1" class="blocReponse">
                <div class="fondBlocReponse">
                    <div :class="classReponseAafficher(1)" @click="!this.reponseEliminerParJoker5050.includes(1) && !this.reponseValidee  ? clicReponse(1) : null">
                        {{ localReponse[1] }}
                    </div>
                </div>
            </div>
            <!-- REPONSE 3 -->
            <div v-if="localReponse.length > 2" class="blocReponse">
                <div class="fondBlocReponse">
                    <div :class="classReponseAafficher(2)" @click="!this.reponseEliminerParJoker5050.includes(2) && !this.reponseValidee  ? clicReponse(2) : null">
                        {{ localReponse[2] }}
                    </div>
                </div>
            </div>
            <!-- REPONSE 4 -->
            <div v-if="localReponse.length > 3" class="blocReponse">
                <div class="fondBlocReponse">
                    <div :class="classReponseAafficher(3)" @click="!this.reponseEliminerParJoker5050.includes(3) && !this.reponseValidee  ? clicReponse(3) : null">
                        {{ localReponse[3] }}
                    </div>
                </div>

            </div>
        </div>

        <!-- FOOTER : Bouton joker et indice -->
        <div v-if="!reponseValidee" class="partieBoutonPendantQuestion">
            <div class="conteneurBoutonFooterCentral" >
                <div v-if="localReponse.length > 2 && !joker5050utilise" class="boutonJoker5050" @click="clicJoker5050()">
                    Joker 50/50   
                </div>
            </div>
            <div v-if="localIndice != ''" class="boutonIndice" @click="clicIndice()">
                 Indice 
            </div>
        </div>

        <div v-if="reponseValidee" class="partieBoutonApresQuestion">
            <div class="texteMessageApresReponse">
                {{ messageApresReponse }}
            </div>
            <div class="boutonPasserAlanecdote" @click="clicPasserAlanecdote()">
                 Suivant 
            </div>
        </div>

    <!-- ANECDOTE  -->
        <div v-if="voirAnecdote" class="partieAnecdote">
            <div class="partieBlockAnecdote">
                <AfficheurDataBlock :localDataBlock="localAnecdote" :isLegendeSurAgrandissementImage="true"/>
            </div>
            <div class="partieBoutonPendantQuestion">
                <div class="conteneurBoutonFooterCentral" >
                    <div class="boutonJoker5050" @click="clicRetourALaQuestion()">
                        Retour
                    </div>
                </div>
            <div class="boutonQuestionSuivant" @click="clicChargerQuestionSuivante()">
                 Suivant 
            </div>
        </div>
        </div>

        <!-- INDICE  -->
        <div v-if="voirIndice" class="partieIndice">
            <div class="partieExterieurBlockIndice">
                <div class="partieBlockIndice">
                    <div class="partieInterieurIndice">
                        <AfficheurDataBlock :localDataBlock="localIndice" :isLegendeSurAgrandissementImage="false"/>
                    </div>
                </div>
            </div>
            <div class="boutonRetourApresIndice" @click="clicFermerIndice()">
                OK
            </div>
        </div>

        <!-- BOUTON QUITTER JEU  -->
        <!-- bouton quitter inutile car nous n'avons qu'un seul jeu pour le momment <AfficheurBoutonQuitterJeu @clicQuitter="clicChargerQuestionSuivante" /> -->

    </div>

</template>


<script lang="ts">
import { defineComponent  } from 'vue';
import AfficheurDataBlock from '../objetsAafficher/AfficheurDataBlock.vue'; 
//import AfficheurBoutonQuitterJeu from '../objetsAafficher/AfficheurBoutonQuitterJeu.vue'; 
import { ouvrirPopup2texte2Bouton  } from '../objetsAafficher/InteractionPopup';
import { lancerSon, stopSon } from '../objetsAafficher/InteractionDiffuseurSon';

export default defineComponent ({
props:['objetQuestion'],
components: {
    AfficheurDataBlock 
    //AfficheurBoutonQuitterJeu
  },
data() {
    return {
        localObjectQuestion : '' as any,
      localQuestion: '' as any,
      localAnecdote: '' as any,
      localIndice: '' as any,
      joker5050utilise: false as boolean,
      reponseEliminerParJoker5050: [] as any,
      urlImageQuestion: '' as string, 
      localReponse: [] as Array<string>,
      indexBonneReponse: -1 as number,
      reponseDonnee: -1 as number,
      reponseValidee: false as boolean,
      messageApresReponse: '' as string,
      voirAnecdote: false as boolean,
      voirIndice: false as boolean,
      loading: false,
      messageErreur: '' as any
    };
  },
  
methods:{
    // on recupere la bonne facon d'afficher la reponse en fonction du joker 5050, la reponse donnee ...
    classReponseAafficher(inIndexReponse : number) { 
        let classes = [];
        // si reponse eliminer par 5050 => reponse grisee
        if (this.reponseEliminerParJoker5050.includes(inIndexReponse)) classes.push('interieurBlocReponseDesactiveParJoker5050');
        // si la reponse a ete valide ET que cette reponse est la bonne => reponse en vert
        else if (this.reponseValidee && this.indexBonneReponse == inIndexReponse) classes.push('interieurBlocReponseBonneReponse');
        // si la reponse a ete valide ET que c'est une mauvaise reponse => on l'affiche en rouge
        else if (this.reponseValidee && this.reponseDonnee == inIndexReponse && this.reponseDonnee != this.indexBonneReponse) classes.push('interieurBlocReponseMauvaiseReponse');
        // sinon => on affiche la reponse sur fond blanc
        else classes.push('interieurBlocReponse');
        return classes;
    },
    clicReponse(inNumReponse : number) {
        //("Clic reponse" + inNumReponse);

        this.reponseDonnee = inNumReponse;

        ouvrirPopup2texte2Bouton("Votre réponse : ",this.localObjectQuestion.reponses[this.reponseDonnee].texte, 'Annuler', this.clicAnnulerReponse,'Valider', this.clicValiderReponse);
    },
    clicAnnulerReponse() {

        this.reponseDonnee = -1;
    },
    clicValiderReponse() {

        this.reponseValidee = true;

        if(this.reponseDonnee != -1 && this.localObjectQuestion.reponses[this.reponseDonnee].selectionne) {

            this.messageApresReponse = "Félicitations ! "+this.localObjectQuestion.reponses[this.reponseDonnee].texte+" est la bonne réponse !";
        }
        else {
            this.messageApresReponse = "Dommage ! "+this.localObjectQuestion.reponses[this.indexBonneReponse].texte+" était la bonne réponse !";
        }
    },
    clicJoker5050() {
    
        if(this.localReponse.length > 2) {
            //console.log("Clic joker 50 50");

            // on selectionne les reponses qu'on a le droit d'elimier
            let reponseEliminable = [];
            for(let r = 0; r < this.localObjectQuestion.reponses.length; r++){
                if(!this.localObjectQuestion.reponses[r].selectionne){
                    reponseEliminable.push(r);
                }
            }

            // on selectionne aleatoirement la premiere reponse a eliminer
            let randomNumber = this.getRandomInt(0, reponseEliminable.length-1); // on recupere l'index d'une reponse a eliminer au hasard
            this.reponseEliminerParJoker5050.push(reponseEliminable[randomNumber]); // on ajout cette index au reponse a eliminer
            //console.log("Retirer reponse : " + this.reponseEliminerParJoker5050[0]);

            reponseEliminable.splice(randomNumber, 1); // on retire cette index des reponse eliminable
            
            // si on a plus de 3 reponses possibles
            if(this.localReponse.length > 3) {
                // on selectionne aleatoirement la deuxieme reponse a eliminer
                let randomNumber = this.getRandomInt(0, reponseEliminable.length-1);  // on recupere l'index d'une reponse a eliminer au hasard
                this.reponseEliminerParJoker5050.push(reponseEliminable[randomNumber]); // on ajout cette index au reponse a eliminer
                //console.log("Retirer reponse : " + this.reponseEliminerParJoker5050[1]);

            }

            this.joker5050utilise = true;
        }
    },
    clicIndice() {
        //console.log("Clic indice");

        this.voirIndice = true;

        lancerSon(this.localIndice, true, false);
    }, 
    clicPasserAlanecdote(){
        //console.log("Clic passer anecdote");

        this.voirAnecdote = true;

        lancerSon(this.localAnecdote, false, true);
    },
    clicRetourALaQuestion(){
        //console.log("Clic passer anecdote");

        this.voirAnecdote = false;

        lancerSon(this.localQuestion, false, false);
    },
    clicFermerIndice(){
        //console.log("Clic fermer indice");
        
        this.voirIndice = false;
        
        lancerSon(this.localQuestion, false, false);
    },
    clicChargerQuestionSuivante(){
        //console.log("Clic charger question suivante");

        // on stop le son avant de quitter la question
        stopSon();

        this.$emit('clicPasserQuestionSuivante');
    },
    getRandomInt(min : number, max : number) {
        min = Math.ceil(min); // Ensure the minimum is rounded up to the nearest integer
        max = Math.floor(max); // Ensure the maximum is rounded down to the nearest integer
        return Math.floor(Math.random() * (max - min + 1)) + min;
}
},
created() {
        this.localObjectQuestion = JSON.parse(this.objetQuestion);
        // This will be executed as soon as the Vue instance is created
        this.localQuestion = this.localObjectQuestion.question[0];

        if(this.localObjectQuestion.indices != null && this.localObjectQuestion.indices.length > 0 && this.localObjectQuestion.indices[0] != null){
            this.localIndice = this.localObjectQuestion.indices[0];
        }
        
        this.localAnecdote = this.localObjectQuestion.astuce[0];

        // Clear the array
        this.localReponse.splice(0, this.localReponse.length);

        if(this.localObjectQuestion.reponses != null && this.localObjectQuestion.reponses != null) {
                for(let r = 0; r < this.localObjectQuestion.reponses.length; r++){
                        //console.log(this.localObjectQuestion.reponses[r].texte);
                        this.localReponse.push(this.localObjectQuestion.reponses[r].texte);
                        
                        if(this.localObjectQuestion.reponses[r].selectionne){
                            this.indexBonneReponse = r;
                        }
                }
        }  
        
        // lancement son question
        lancerSon(this.localQuestion, true, false);
    }

});

</script>




<style scoped>

.fullscreen-div {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
}

.partieChargementInitial {
    width: 100%;
    height: 100%;
    padding: 10px;
    position: fixed;
    margin: 0;
    background: white;
    border-radius: 0px;
}

/*********************************
********* PARTIE QUESTION ********
*********************************/

.partieQuestion{
    box-sizing: border-box;
    width: 100%;   
    height: 52%;
    padding: 1%;
    position: relative;
    margin: 0;
    background: white;
    border-radius: 0px;
}

/*********************************
********* PARTIE REPONSE *********
*********************************/

.partieReponse {
    box-sizing: border-box;
    width: 100%;  
    height: 40%;
    padding: 1%; 
    position: relative;
    margin: 0;
    background: white;
    border-radius: 0px;
    /* pour centrer les reponses en cas de 2 ou 3 reponses au lieu de 4*/
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}


/******************************************************************
**** FORMAT BLOC REPONSE => CHANGE EN FONCTION DE TAILLE ECRAN ****
******************************************************************/
/* Si format paysage */
@media screen and (min-aspect-ratio: 1/1) {

  .blocReponse{
        box-sizing: border-box;
        /* reponse 2 par 2 */
        width: 50%;  
        height: 50%;  
        padding: 1%;
        /* met le bloc a la suite du bloc precedant */
        position: relative;
        float : left;
        top: 0;
        left: 0;
        margin: 0;
        /* typo quicksand bold */
        font-family: 'QuicksandBold';
        /* evite les defomations du a display: flex*/
        flex-shrink: 0; 
        flex-grow: 0; 
    }
}

/* si format portrait */
@media screen and (max-aspect-ratio: 1/1) {
  .blocReponse{
        box-sizing: border-box;
        /* reponse 4 par 1 */
        width: 100%;  
        height: 25%; 
        padding: 1%;
        /* met le bloc a la suite du bloc precedant */
        position: relative;
        float : top;
        top: 0;
        left: 0;
        margin: 0;
        /* typo quicksand bold */
        font-family: 'QuicksandBold';
        /* evite les defomations du a display: flex*/
        flex-shrink: 0; 
        flex-grow: 0; 
    }
}

.fondBlocReponse{
    box-sizing: border-box;
    width: 100%;  /* Width of the rectangle */
  height: 100%; /* Height of the rectangle, change as needed */
  background-color: #2F3E53; /* Color of the rectangle */
  border-radius: 10px; /* The radius of the rounded corners */

}

/***************************************
**** REPONSE AVANT JOKER ET REPONSE ****
****************************************/

.interieurBlocReponse{
    box-sizing: border-box;
    /* largeur interieur avec deacalage pour laisser marge */
    width: 98%;  
    left: 1%;
    height: 94%; /* Height of the rectangle, change as needed */
    top: 3%;
    position: relative;

    padding: 0;

    background-color: white; /* Color of the rectangle */
    border-radius: 10px; /* The radius of the rounded corners */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;

    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(1vw + 1vh + 1vmin);

}

/**********************************
**** REPONSE BONNE SELECTIONNE ****
***********************************/

.interieurBlocReponseBonneReponse {
box-sizing: border-box;
    /* largeur interieur avec deacalage pour laisser marge */
    width: 98%;  
    left: 1%;
    height: 94%; /* Height of the rectangle, change as needed */
    top: 3%;
    position: relative;

    padding: 0;
    color: white;
    background-color: rgba(53, 99, 60, 0.8);
    border-radius: 10px; /* The radius of the rounded corners */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;

    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(1vw + 1vh + 1vmin);
}

/*****************************************
**** REPONSE DESACTIVE PAR JOKER 5050 ****
******************************************/
.interieurBlocReponseDesactiveParJoker5050 {
box-sizing: border-box;
    /* largeur interieur avec deacalage pour laisser marge */
    width: 98%;  
    left: 1%;
    height: 94%; /* Height of the rectangle, change as needed */
    top: 3%;
    position: relative;

    padding: 0;

    color: rgba(47, 62, 83, 1);
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px; /* The radius of the rounded corners */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;

    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(1vw + 1vh + 1vmin);
}

/*************************************
**** REPONSE MAUVAISE SELECTIONNE ****
**************************************/
.interieurBlocReponseMauvaiseReponse {
    box-sizing: border-box;
    /* largeur interieur avec deacalage pour laisser marge */
    width: 98%;  
    left: 1%;
    height: 94%; /* Height of the rectangle, change as needed */
    top: 3%;
    position: relative;

    padding: 0;
    color: white;
    background-color: rgba(125, 15, 19, 0.8);
    border-radius: 10px; /* The radius of the rounded corners */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;

    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(1vw + 1vh + 1vmin);
}

/*********************************
******** PARTIE ANECDOTE *********
*********************************/

.partieAnecdote {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 100%;  /* 100% of the viewport height */
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    background: violet;
    border-radius: 0px;
}

.partieBlockAnecdote {
    box-sizing: border-box;
    width: 100%;  /* 100% of the viewport width */
    height: 92%;  /* 50% of the viewport height */
    padding: 1%;
    position: relative;
    margin-top: 0;
    background: white;
    border-radius: 0px;

}

/*******************************
******** PARTIE INDICE *********
*******************************/

.partieIndice {
    box-sizing: border-box;
    width: 100%;   /* 100% of the viewport width */
    height: 100%;  /* 100% of the viewport height */
    padding: 0;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0px;
}

.partieExterieurBlockIndice {
    box-sizing: border-box;
    width: 100%; 
    height: 92%; 
    padding: 3%;
    position: relative;
    background: rgba(255, 255, 255, 0);
    border-radius: 20px;
}

.partieBlockIndice {
    box-sizing: border-box;
    width: 100%; 
    height: 100%; 
    padding: 0.5%;
    position: relative;
    background: black;
    border-radius: 20px;
}

.partieInterieurIndice{
    box-sizing: border-box;
    width: 100%; 
    height: 100%; 
    padding: 2%;
    position: relative;
    margin-top: 0;
    background: white;
    border-radius: 20px;
}


.boutonRetourApresIndice {
    width: 30%;  
    height: 8%;  
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: relative;
    float : right; 
    top: 0;
    right: 0;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: #587ABC; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px 25px 0 0; /* Top-left, top-right, bottom-right, bottom-left */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}

/***************************************************
********* FOOTER : Bouton joker et indice *********
****************************************************/
.partieBoutonPendantQuestion {
    box-sizing: border-box;
    width: 100%;  /* 100% of the viewport width */
    height: 8%;  /* 50% of the viewport height */
    padding: 0;
    position: relative;
    margin-top: 0;
    background: white;
    border-radius: 0px;

    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(0.7vw + 0.7vh + 0.7vmin);
}


.boutonJoker5050{
    width: 100%;  /* 100% of the viewport width */
    height: 100%;  /* 50% of the viewport height */
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: relative;
    float : left; 
    top: 0;
    left: 0;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: #587ABC; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px 25px 0 0; /* Top-left, top-right, bottom-right, bottom-left */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}

.boutonIndice{
    width: 30%;  /* 100% of the viewport width */
    height: 100%;  /* 50% of the viewport height */
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: relative;
    float : left; 
    top: 0;
    left: 0;
    margin-left: 5%;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: #587ABC; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px 25px 0 0; /* Top-left, top-right, bottom-right, bottom-left */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}


.boutonQuestionSuivant{
    width: 30%;  /* 100% of the viewport width */
    height: 100%;  /* 50% of the viewport height */
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: relative;
    float : left; 
    top: 0;
    left: 0;
    margin-left: 5%;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: #2F3E53; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px 25px 0 0; /* Top-left, top-right, bottom-right, bottom-left */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;

}

/*****************************************
********* FOOTER : apres reponse *********
******************************************/

.partieBoutonApresQuestion {
    box-sizing: border-box;
    width: 100%;  /* 100% of the viewport width */
    height: 8%;  /* 50% of the viewport height */
    padding: 0;
    position: relative;
    margin-top: 0;
    background: rgba(255, 255, 255, 0);
    border-radius: 0px;

    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(0.7vw + 0.7vh + 0.7vmin);
}

.texteMessageApresReponse{
    box-sizing: border-box;
    /* largeur interieur avec deacalage pour laisser marge */
    width: 35%;  
    height: 100%;  
    left: 1%;
    position: relative;
    float : left; 
    padding: 0;
    margin-left: 35%;
    background-color: rgba(255, 255, 255, 0); /* Color of the rectangle */
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    color: #2F3E53;
    border-radius: 10px; /* The radius of the rounded corners */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;

    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(0.8vw + 0.8vh + 0.8vmin);

}

.boutonPasserAlanecdote {
    width: 25%;  /* 100% of the viewport width */
    height: 100%;  /* 50% of the viewport height */
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: relative;
    float : left; 
    top: 0;
    left: 0;
    margin-left: 5%;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: #2F3E53; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px 25px 0 0; /* Top-left, top-right, bottom-right, bottom-left */
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}


.conteneurBoutonFooterCentral{
    width: 30%;  /* 100% of the viewport width */
    height: 100%;  /* 50% of the viewport height */
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: relative;
    float : left; 
    top: 0;
    left: 0;
    margin-left: 35%;
}


</style>