

<template>
  <!-- ensemble afficheur karaoke -->
  <div v-if="localTempsDefileSon >= 0" class="ensembleAfficheurKaraoke">
    <div class="affichagePhrasePassee">
        {{ phrasePassee }}
    </div>

    <div class="affichagePhraseActuel">
        {{ phraseActuel }}
    </div>

    <div class="affichagePhraseApres">
        {{ phraseApres }}
    </div>

    <!-- bouton fermer l'afficheur karaoke -->
    <button @click="clicFermer" class="boutonFermerAfficheurKaraoke">
      <img :src="logoCroixFermer" alt="Bouton fermer"/>
    </button>
    
  </div>

</template>


<script>
import { watch } from 'vue';
import { InteractionAfficheurKaraoke } from '../objetsAafficher/InteractionAfficheurKaraoke';

export default {
props:[],
data() {
    return {
        phrasePassee: '',
        phraseActuel: '',
        phraseApres: '',
        localTempsDefileSon: -1,
        localParoleKaraoke: '',
        logoCroixFermer: require('@/assets/croixfermer.png')
    };
  },
  methods:{
    clicFermer(){
        InteractionAfficheurKaraoke.paroleKaraoke = '';
    }
  },
  // sur la creation de l'afficher karaoke (dans app)
  created() {
      // on observe le changement de temps dans la probgression  du son dans InteractionAfficheurKaraoke
      watch(() => InteractionAfficheurKaraoke.tempsDefilementSon, (newValue, oldValue) => {

        // si la valeur de temps de progression du son a change
        if (newValue !== oldValue) {

          // si on recoit le temps de progression du son -1 
            if(InteractionAfficheurKaraoke.paroleKaraoke == '' || newValue == -1){
                // => ca veut dire qu'il faut arreter le karaoke
                this.localTempsDefileSon = -1;
                this.phrasePassee =  '';
                this.phraseActuel =  '';
                this.phraseApres =  '';
            }
            // si le son est en cours de progression
            else{
                // on recupere le temps du son et les paroles karaoke
                this.localTempsDefileSon = InteractionAfficheurKaraoke.tempsDefilementSon;
                this.localParoleKaraoke = InteractionAfficheurKaraoke.paroleKaraoke;

                

                let indexPhraseActuel = -1;
                // pour toutes les phrases du karaoke
                for(let p = 0; p < this.localParoleKaraoke.listPhraseKaraoke.length; p++){
                    //console.log("phrase actuel : "+p);
                    // si le temps du son n'a pas encore depasse le temps de fin de la phrase => c'est qu'on est sur la phrase actuel
                    if(this.localTempsDefileSon < this.localParoleKaraoke.listPhraseKaraoke[p].nbSecondeFinPhrase) {
                        // on memorise l'index de la phrase actuelle
                        indexPhraseActuel = p;
                        break;
                    }
                }
                
                // si on n'a pas reussi a trouver la phrase actuelle 
                if(indexPhraseActuel == -1) {
                    // on met la derniere phrase
                    indexPhraseActuel = this.localParoleKaraoke.listPhraseKaraoke.length - 1;
                }

                // on met a jour les phrases a affichees avec l'index de la phrase actuelle
                // on affiche la phrase precedante si on n'est pas sur la premiere phrase
                if(indexPhraseActuel > 0)
                    this.phrasePassee = this.localParoleKaraoke.listPhraseKaraoke[indexPhraseActuel-1].phrase;
                else
                    this.phrasePassee =  '';

                // on affiche la phrase actuelle
                this.phraseActuel =  this.localParoleKaraoke.listPhraseKaraoke[indexPhraseActuel].phrase;

                //console.log("Karoake : phrase actuelle : "+indexPhraseActuel);

                // on affiche la phrase suivant si on n'est pas sur la derniere phrase
                if(indexPhraseActuel < this.localParoleKaraoke.listPhraseKaraoke.length - 1)
                    this.phraseApres =  this.localParoleKaraoke.listPhraseKaraoke[indexPhraseActuel+1].phrase;
                else
                    this.phraseApres =  '';

            }
        }
  });
    }
}

</script>




<style scoped>


.ensembleAfficheurKaraoke {
    box-sizing: border-box;
    width: 100%;   
    height: 100%;
    position: fixed;
    padding: 0;
    float : left; 
    top: 0;
    right: 0;
    margin: 0;
    background: whitesmoke;
    border-radius: 0px;
    background-color: white; /* Color of the rectangle */
     /* typo quicksand bold */
    font-family: 'QuicksandBold';

}

.affichagePhrasePassee {

    box-sizing: border-box;
    width: 100%;   
    height: 33%;
    padding: 3%;
    position: relative;
    float: top;
    margin: 0;
    background: whitesmoke;
    color: gray;
    border-radius: 0px;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(1vw + 1vh + 1vmin);
}

.affichagePhraseActuel {

    box-sizing: border-box;
    width: 100%;   
    height: 34%;
    padding: 3%;
    position: relative;
    float: top;
    margin: 0;
    background: yellow;
    color: black;
    border-radius: 0px;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(2vw + 2vh + 2vmin);
}

.affichagePhraseApres {

    box-sizing: border-box;
    width: 100%;   
    height: 33%;
    padding: 3%;
    position: relative;
    float: top;
    margin: 0;
    background: whitesmoke;
    color: black;
    border-radius: 0px;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
    /* taille texte 3% de la taille de l'ecran */
    font-size: calc(1vw + 1vh + 1vmin);
}

.boutonFermerAfficheurKaraoke {

  width: 10%;  /* 100% of the viewport width */
    height: 8%;  /* 50% of the viewport height */
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: fixed;
    float : left; 
    top: 0;
    right: 0;
    margin: 0;
    border: 0;
    
    /* texte blanc */
    color: black;
    /* fond bleu */
    background-color: whitesmoke; /* Color of the rectangle */
}

.boutonFermerAfficheurKaraoke img {
  max-width: 90%;
  max-height: 90%;
  position: relative;
  float : right; 
  filter: brightness(0) saturate(100%) hue-rotate(90deg);
  margin-right: 10%;
  margin-top: 10%;
  display: block; /* Prevents inline default spacing */
  object-fit: contain; /* Ensures the image is scaled to be contained within the button without distorting its aspect ratio */
}

</style>