import { ref, onMounted, onUnmounted } from 'vue';

export function useScreenOrientation() {
    const orientation = ref('portrait');
    const ratio = ref(window.innerWidth / window.innerHeight);

    // Function to update orientation and ratio
    const updateOrientation = () => {
        orientation.value = window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
        ratio.value = window.innerWidth / window.innerHeight;
    };

    // Set up the event listener for window resize
    onMounted(() => {
        window.addEventListener('resize', updateOrientation);
        updateOrientation(); // Update immediately on mount
    });

    // Clean up the event listener when the component is unmounted
    onUnmounted(() => {
        window.removeEventListener('resize', updateOrientation);
    });

    return { orientation, ratio };
}
