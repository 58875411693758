// store.js
import { reactive } from 'vue';

export const InteractionDiffuseurVideo = reactive({
  donneeVideo: null as any
});


export function stopVideo() {
  InteractionDiffuseurVideo.donneeVideo = null;
}

export function lancerVideo(inDonneeVideo : any) {
  InteractionDiffuseurVideo.donneeVideo = inDonneeVideo;
}