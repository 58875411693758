import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6085eaac"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "ensemblePopup"
}
const _hoisted_2 = {
  key: 0,
  class: "affichageTextePopupPleinEcran"
}
const _hoisted_3 = {
  key: 1,
  class: "affichagePopupAvecDeuxTexte"
}
const _hoisted_4 = { class: "affichageTitrePopup" }
const _hoisted_5 = { class: "affichageTextePopup" }
const _hoisted_6 = { class: "ensembleBouton" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.textePopup != '')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.titrePopup == '')
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.textePopup), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.titrePopup), 1),
              _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.textePopup), 1)
            ])),
        _createElementVNode("div", _hoisted_6, [
          (_ctx.texteSurBouton1 != '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "boutonPopup",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clicBouton1()))
              }, _toDisplayString(_ctx.texteSurBouton1), 1))
            : _createCommentVNode("", true),
          (_ctx.texteSurBouton2 != '')
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "boutonPopup",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clicBouton2()))
              }, _toDisplayString(_ctx.texteSurBouton2), 1))
            : _createCommentVNode("", true)
        ])
      ]))
    : _createCommentVNode("", true)
}