// store.js
import { reactive } from 'vue';

export const InteractionDiffuseurSon = reactive({
  dataBlock: null as any, 
  lancerSonToutSuite: false as boolean,
  lancerKaraoke: false as boolean
});


export function stopSon() {
  InteractionDiffuseurSon.dataBlock = null;
  InteractionDiffuseurSon.lancerSonToutSuite = false;
  InteractionDiffuseurSon.lancerKaraoke = false;
}

export function lancerSon(inDataBlock : any, inLancerSonToutSuite : boolean, inLancerKaraokeSurPlay : boolean) {
  InteractionDiffuseurSon.dataBlock = inDataBlock;
  InteractionDiffuseurSon.lancerSonToutSuite = inLancerSonToutSuite;
  InteractionDiffuseurSon.lancerKaraoke = inLancerKaraokeSurPlay;
}
