

<template>
 <!-- ensemble diffuseur son -->
  <div v-if="this.sonUrl != ''" class="ensembleDiffuseurSon">
    <div class="espaceAffichageBouton"> 
       <!-- bouton play -->
      <button v-if="this.sound != null && !this.sound.playing()" @click="play" class="boutonImage">
          <img :src="logoPlay" alt="Bouton play"/>
      </button>
       <!-- bouton pause -->
      <button v-if="this.sound != null && this.sound.playing()" @click="pause" class="boutonImage">
          <img :src="logoPause" alt="Bouton pause"/>
      </button>
       <!-- bouton stop -->
      <button @click="stop" class="boutonImage">
          <img :src="logoStop" alt="Bouton stop"/>
      </button>
    </div>
     <!-- temps restant -->
    <div class="espaceAffichageTempsRestant"> 
      {{ currentTime }}
    </div> 
    <div v-if="chargementEnCours" class="divChargementEnCours"> 
      chargement en cours...
    </div> 

  </div>
</template>


<script>
import { Howl } from 'howler';
import { InfoServeur, ConstServeur } from '../objetsTypeScript/InfoServeur';
import { lectureObjetBDDVsID } from '../fonction/fonctionServeur';
import { watch } from 'vue';
import { InteractionDiffuseurSon } from '../objetsAafficher/InteractionDiffuseurSon';
import { InteractionAfficheurKaraoke } from '../objetsAafficher/InteractionAfficheurKaraoke';

const MINIMUM_PHRASE_KARAOKE = 5;

export default {
props:[],
data() {
    return {
      localDataBlock: '',
      lancerSonToutSuite: false,
      lancerKaraoke: false,
      chargementEnCours: true,
      sonUrl: '',
      sound: null,
      interval: null,
      currentTime: 0, 
      logoPlay: require('@/assets/play.png'),
      logoPause: require('@/assets/pause.png'),
      logoStop: require('@/assets/stop.png'),
      localDonneeSon: '', 
      localExtrait: '',
      timingDebutSon : 0, 
      timingFinSon : 0
    };
  },
methods:{
    // on initialise le diffuseur son avec une url vers un son
    playSound(inUrlSon, inLancerSonToutSuite) {
        // on change l'url du son
        this.sonUrl = inUrlSon;

        // on charge le son
        this.sound = new Howl({
          src: [inUrlSon],
          // on attend que son soit charge
          onload: () => {    
            // des que le son est charge
            this.chargementEnCours = false;
            // on affiche le temps de l'extrait
            this.resetTime();

            // on lance le son si il faut le lancer des que charge
            if(inLancerSonToutSuite) {
              console.log("Lancer le son tout de suite !");
              // lancer le son
              this.play();
            }
          }
        });

          
        },
        
        // lancer le son
        play() {
          // on commence par active ou non le karaoke
          // si on a une donnee bibliotheque et des paroles karaoke dessus
          if(this.lancerKaraoke && this.localDonneeSon != '' && this.localDonneeSon.paroleKaraoke != null && this.localDonneeSon.paroleKaraoke.listPhraseKaraoke != null && this.localDonneeSon.paroleKaraoke.listPhraseKaraoke.length > MINIMUM_PHRASE_KARAOKE){
            // on lance le karaoke
            InteractionAfficheurKaraoke.paroleKaraoke = this.localDonneeSon.paroleKaraoke;
          }
          else {
            // sinon pas de karaoke
            InteractionAfficheurKaraoke.paroleKaraoke = '';
          }

          // on lance le son
          this.sound.play();
          // si le son est avant le debut => on met le son au debut
          if( this.sound.seek() < this.timingDebutSon){
              this.sound.seek(this.timingDebutSon);
            }
          // on track le temps d'avancement du son
          this.startTrackingTime();

          
        },
        // mettre le son en pause
        pause() {
          this.sound.pause();
          this.stopTrackingTime();
        },
        // arreter le son
        stop() {
          console.log("clic stop son");
          if (this.sound && this.sound.playing()) {
            this.sound.stop();
            this.resetTime();
            this.stopTrackingTime();
          }
        },
        // on commence a tracker le temps du player son
        startTrackingTime() {
          if (this.interval) return;

          this.interval = setInterval(() => {
            // si on n'a pas de temps de debut et fin d'extrait 
            // ou si le temps de l'extrait est superieur a la duree du son
            // => on joue le son en entier
            if(   (this.timingDebutSon == 0 && (this.timingFinSon == 0 || this.timingFinSon == 99999))
              ||  ((this.timingFinSon - this.timingDebutSon) > this.sound.duration())
              ){
              // on affiche le temps restant sur la piste sonore
              this.currentTime = (this.sound.duration() - this.sound.seek() || 0).toFixed(2);
            }
            // si on a un temps de fin d'extrait superieur a 0
            else{
              // on affiche le temps restant jusqu'a la fin de l'extrait
              this.currentTime = (this.timingFinSon - this.sound.seek() || 0).toFixed(2);

              // si on atteint ou depasse la fin de l'extrait => on arrete le son
              if(this.sound.seek() >= this.timingFinSon || this.sound.seek() >= this.sound.duration()){
                this.stop();
              }
            }

            // on indique la progression du son a l'afficheur karaoke
            InteractionAfficheurKaraoke.tempsDefilementSon = this.sound.seek();

            
          }, 100);
        },
        // on arrete de tracker le temps du player son
        stopTrackingTime() {
          if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
            // on stop le karaoke
            InteractionAfficheurKaraoke.paroleKaraoke = '';
          }
        },
        // on reset le temps restant gt
        resetTime() {
          // si on n'a pas de temps de fin d'extrait
          if(this.timingDebutSon == 0 && this.timingFinSon == 0) {
            // on affiche la duree total du son
            this.currentTime = (this.sound.duration()).toFixed(2);
          }
          // si on a un temps de fin d'extrait
          else{
            // on affiche la duree entre le debut et la fin de l'extrait
            this.currentTime = (this.timingFinSon - this.timingDebutSon).toFixed(2);
          }
          
        },
        // avant la destruction du diffuseur son
      beforeDestroy() {
        this.stopTrackingTime();
        if (this.sound) {
          this.sound.unload();
        }
      },
      avanceCinqSecond(){
        if (this.sound && this.sound.playing()) {
            let current = this.sound.seek();  // Get current playback time
            this.sound.seek(current + 5);     // Move forward by 5 seconds
        }
      },
      reculeCinqSecond(){
        if (this.sound && this.sound.playing()) {
            let current = this.sound.seek();  // Get current playback time
            this.sound.seek(current - 5);     // Move forward by 5 seconds
        }
      }
  },
  // sur la creation du diffuseur son
  created() {
       console.log("Init diffuseur son");
        // on observe le changement de dataBlock dans InteractionDiffuseurSon
      watch(() => InteractionDiffuseurSon.dataBlock, (newValue, oldValue) => {

        
        // si on a un nouveau datablock
        if (newValue !== oldValue) {
          this.chargementEnCours = true;
          console.log("Modif dataBlock diffuseur son");

          // si pas de data block => stop son
            if(newValue == null){
              this.localDataBlock = '';
              this.lancerSonToutSuite = false;
              this.lancerKaraoke = false;
              this.sonUrl = '';
              // on stop le son d'avant
              this.stop();
            }
            // si data block => on lance le son
            else{
              // on stop le son d'avant
              this.stop();

              // on init le diffuseur son avec le nouveau datablock
              this.localDataBlock = InteractionDiffuseurSon.dataBlock;
              this.lancerSonToutSuite = InteractionDiffuseurSon.lancerSonToutSuite;
              this.lancerKaraoke = InteractionDiffuseurSon.lancerKaraoke;

              // si on a un contenus bibliotheque son
              if(this.localDataBlock.idDonneeSon != -1) {
                console.log("Lancer son bibliotheque : " + this.localDataBlock.idDonneeSon);
                // on recupere la donnee son dans la bibliothque
                lectureObjetBDDVsID(this.localDataBlock.idDonneeSon.toString(), ConstServeur.NOM_TABLE_SON_BIBLI).then(data => {
                    // si on a reussi a recuperer la donnee son
                    if(data != '') {
                      // on recupere la donnee son a partir du json
                      this.localDonneeSon = JSON.parse(data);
                      // on recupere l'extrait que l'on doit jouer
                      this.localExtrait = this.localDonneeSon.listExtraitSon[this.localDataBlock.pointeurExtraitSon];
                      // on defini le temps de debut et de fin d'extrait
                      this.timingDebutSon = this.localExtrait.nbSecondDebut;
                      this.timingFinSon = this.localExtrait.nbSecondFin;
                      // on lance son stocke dans le repertoire bilbiotheque son
                      this.playSound(InfoServeur.urlServeur + InfoServeur.repertoireSonBibli + this.localDonneeSon.nomServeur, this.lancerSonToutSuite);
                    }
                    // si on n'a pas reussi a recuperer la donnee son
                    else{
                      // on annule la lecture du son
                      this.localDataBlock = '';
                      this.localDonneeSon = '';
                      this.lancerSonToutSuite = false;
                      this.lancerKaraoke = false;
                      this.sonUrl = '';
                      console.log("Impossible de charger "+this.localDataBlock.idDonneeSon.toString()+" dans bibliotheque_son");
                    }
                });
              }
              // si on a une son directement sur la question
              else if(this.localDataBlock.cheminSon != '') {
                // on reset les temps de debut et fin d'extrait
                this.timingDebutSon = 0;
                this.timingFinSon = 0;
                this.localDonneeSon = '';
                console.log("Lancer son direct sur question : " + InfoServeur.urlServeur+InfoServeur.repertoireSon+this.localDataBlock.cheminSon);
                // on lance le son stocke dans le repertoire son question
                this.playSound(InfoServeur.urlServeur+InfoServeur.repertoireSon+this.localDataBlock.cheminSon, this.lancerSonToutSuite);
              }
              // si pas de donnee son et pas de son direct sur datablock
              else{
                // on annule la lecture du son
                this.localDataBlock = '';
                this.localDonneeSon = '';
                this.lancerSonToutSuite = false;
                this.sonUrl = '';
              }
          }
        }
      });
    }
}

</script>




<style scoped>

.ensembleDiffuseurSon {
    box-sizing: border-box;
    width: 200px;  /* 25% of the viewport width */
    height: 5%;
    padding: 0;
    /*min-width: 200px; *//* Minimum width of 200 pixels */
    position: fixed;
    float : left; 
    top: 0;
    right: 0;
    margin: 0;
    background: whitesmoke;
    border-radius: 0px;
    background-color: #2F3E53; /* Color of the rectangle */
    color: white;
    /* bord arrondi */
    border-radius: 25px;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}


/**********************
**** TEMPS RESTANT ****
***********************/

.espaceAffichageTempsRestant {
  box-sizing: border-box;
  width: 50%;  
  height: 100%; 
  padding: 2%;
  /* met le bloc a la suite du bloc precedant */
  position: relative;
  float : left; 
  top: 0;
  left: 0;
  margin: 0;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}

/**********************************
**** BOUTONS (PLAY,PAUSE,STOP) ****
**********************************/

.espaceAffichageBouton {
  box-sizing: border-box;
  width: 50%;  
  height: 100%; 
  padding: 2%;
  /* met le bloc a la suite du bloc precedant */
  position: relative;
  float : left; 
  top: 0;
  left: 0;
  margin: 0;
}

.boutonImage {
  box-sizing: border-box;
  margin: 0;
  float: left;
  width: 50%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0);
  border-width: 0;
}

.boutonImage img {
  max-width: 100%;
  max-height: 100%;
  display: block; /* Prevents inline default spacing */
  object-fit: contain; /* Ensures the image is scaled to be contained within the button without distorting its aspect ratio */
}


.divChargementEnCours {
    box-sizing: border-box;
    width: 200px;  /* 25% of the viewport width */
    height: 5%;
    padding: 0;
    /*min-width: 200px; *//* Minimum width of 200 pixels */
    position: fixed;
    float : left; 
    top: 0;
    right: 0;
    margin: 0;
    background: whitesmoke;
    border-radius: 0px;
    background-color: #2F3E53; /* Color of the rectangle */
     /* typo quicksand bold */
    font-family: 'QuicksandBold';
    color: white;
    /* bord arrondi */
    border-radius: 25px;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>