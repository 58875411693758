<template>
    <!-- affichage des boutons permettant de lancer different type de question
    <div class="boutonAfficherQuestion" @click="clicQuestionId(7449)">
         100% texte
    </div>  

    <div class="boutonAfficherQuestion" @click="clicQuestionId(25784)">
        Image bibliotheque
    </div>  

    <div class="boutonAfficherQuestion" @click="clicQuestionId(25826 )">
        Image et son bibliotheque
    </div>  

    <div class="boutonAfficherQuestion" @click="clicQuestionId(6197)">
        Image et son sur datablock
    </div> 

    <div class="boutonAfficherQuestion" @click="clicQuestionId(26249)">
        100% Vidéo
    </div>  -->

  <!-- chargement de l'etablissement -->
  <div v-if="chargementEtablissementEnCours" class="textePleinEcran">
    Chargement de l'établissement en cours
  </div>
  <!-- si pas d'erreur ET on a un etablissement -->
  <div v-else-if="messageErreur == '' && localEtablissement != ''" class="ensembleAfficheurEtablissement">
      <!-- on affiche le nom de l'etablissement -->
      <div class="nomDeLetablissement">
          {{ localEtablissement.raisonSocial }}
      </div>

      <!-- on affiche la partie client si client -->
      <div v-if="accesClient" class="partieEtablissement">
        <div class="partieTexteClient">
          Votre établissement a accès à Wivy Famille.<br><br>
          Vous pouvez vous localiser dans l'établissement pour commencer la session en cliquant sur le bouton si dessous.
        </div>
        <div class="boutonCommencer" @click="clicTestPosition()">
          Me localiser et lancer le jeu
        </div>
      </div>
      <div v-else class="partieEtablissement">
        Votre établissement n'a pas accès à Wivy Famille. Parlez en à la personnne en charge de l'animation.
      </div>
  </div>

  <div v-if="chargementQuestionSuivanteEnCours" class="textePleinEcran">
    Chargement question suivante...
  </div>

  <!-- afficheur question QCM-->
  <AffichageQuestionQCM v-if="questionAafficher != ''" @clicQuitterAffichageQuestion="clicFermerQuestion" @clicPasserQuestionSuivante="chargementQuestionSuivante" :objetQuestion="questionAafficher" />

  <!-- diffuseur son commun -->
  <DiffuseurSon/>

  <!-- afficheur karaoke  -->
  <AfficheurKaraoke/>

  <!-- diffuseur video commun -->
  <DiffuseurVideo/>

  <!-- afficheur image en plein ecran -->
  <AfficheurImagePleinEcran/>

  <!-- POPUP -->
  <AfficheurPopup/>



  <!-- si on a une erreur => on l'affiche en plein ecran avec bouton recharger la page -->
  <div v-if="messageErreur" class="ensembleMessageErreur">
    <div class="texteMessageErreur">
      {{ messageErreur }} 
    </div>

    <button @click="clicRechargerLaPage()" class="boutonRechargerLaPage">Recharger la page</button>
  </div>

</template>

<script lang="ts">
import { defineComponent   } from 'vue';
import AffichageQuestionQCM from './components/AffichageQuestionQCM.vue'
import { lectureObjetBDDVsID, lectureObjetQuestionSuivante, enregistrementMajSession } from './fonction/fonctionServeur';
import { ConstServeur, initServeur, InfoServeur } from './objetsTypeScript/InfoServeur';
import DiffuseurSon from './objetsAafficher/DiffuseurSon.vue'; 
import AfficheurKaraoke from './objetsAafficher/AfficheurKaraoke.vue'; 
import DiffuseurVideo from './objetsAafficher/DiffuseurVideo.vue'; 
import AfficheurImagePleinEcran from './objetsAafficher/AfficheurImagePleinEcran.vue'; 
import AfficheurPopup from './objetsAafficher/AfficheurPopup.vue'; 
import { Session, initSession} from './objetsTypeScript/Session';

const LIMIT_DISTANCE_DEVICE_ETABLISSEMENT = 1000; // limit de distance entre le device et l'etablissement pour lancer le mode famille
const typeJeuxChoisi = 1;

// calcul de la distance (metres) entre 2 positions (latitude, longitude)
function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number): number {
  const R = 6371; // Earth's radius in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c * 1000; // Convert to meters
  return distance;
}

  function deg2rad(deg: number): number {
    return deg * (Math.PI / 180);
  }

export default defineComponent ({
props:[],
components: {
  AffichageQuestionQCM,
  DiffuseurSon,
  AfficheurKaraoke,
  DiffuseurVideo,
  AfficheurImagePleinEcran,
  AfficheurPopup
},
data() {
    return {
      questionAafficher : "",
      chargementEtablissementEnCours : true,
      accesClient : false,
      localEtablissement : '' as any,
      messageErreur: '', 
      chargementQuestionSuivanteEnCours : false,
      latitude: 0,
      longitude: 0, 
      sessionActuelle : null as Session | null
    }
  },
  
methods:{
    // lancer question en format json
    clicQuestion(inQuestion : string) {
      
      let localQuestion : any = '';

      // recupereation de la question a partir du JSON
      try {
        localQuestion = JSON.parse(inQuestion);
      } catch (error) {
        localQuestion = '';
      }

      // si pas de probleme avec le recuperation question JSON
      if(localQuestion != ''){
        // si on a une session en cours
        if(this.sessionActuelle != null){
          // on verifie si la session n'a pas depasse la limite de temps
          // si la session est expiree => message erreur session expiree
          if(this.sessionActuelle.sessionExpiree()) {
            this.questionAafficher = '';
            this.messageErreur = "Votre session est expirée. Veuillez recharger la page.";
          }
          // si session encore valide
          else {
            // on la met comme question a afficher (AffichageQuestionQCM fait le reste)
            this.questionAafficher = inQuestion;

            // on ajout la question au questions deja posees + maj heure fin
            this.sessionActuelle.majHeureFinEtAjourContenusJoue(localQuestion.ID);

            // on enregistre la session actuelle
            enregistrementMajSession(JSON.stringify(this.sessionActuelle)).then(idRecup => {
              // si on a reussi a remonter la session actuelle => on a recupere l'id de la session
              if(this.sessionActuelle != null && idRecup != "" && idRecup != -1){
                // on met a jour l'id de la session pour ecraser les futurs enregistrements de la meme session 
                this.sessionActuelle.ID = idRecup;
              }
              // si on n'a pas reussi a remonter la session => erreur dans log mais pas de blocage (c'est pas assez critique)
              else{
                // message erreur
                console.log("Erreur remonte de la session");
              }
            });
          }
        }

      }
      else {
        this.messageErreur = "Impossible de récuperer la question suivante. Veuillez recharger la page.";
      }
    }, 
    // lancer une question en fonction de son id
    clicQuestionId(inId : number) {
      // on scroll
        window.scrollTo({
            top: 100, // Scrolls down 100 pixels
            behavior: 'smooth' // Smooth scroll
        });
      // on va chercher la question dans la BDD avec son ID
      lectureObjetBDDVsID(inId.toString(), ConstServeur.NOM_TABLE_QUESTION_QCM).then(data => {
        // si on a reussi a recuperer la question
        if(data != ''){
          // on lance la question
          this.clicQuestion(data);
        }
        // si on n'a pas reussi a recuperer la question dans la BDD
        else{
          // message erreur
          console.log("Impossible de charger "+inId.toString()+" dans questions_qcm");
        }
      });
    },
    // fonction clic fermer question
    clicFermerQuestion(){

      this.questionAafficher = '';
    },
    // si on clique sur tester la position
     clicTestPosition() {
      console.log("Test position");
      // si on n'est pas sur le serveur wivy => on ne peut pas tester la position (pas de https)
      if(InfoServeur.serveurSelectionne != ConstServeur.SERVEUR_WIVYF || this.localEtablissement.ID == 20888){
        // on lance directement la creation d'une nouvelle session
        this.creationNouvelleSession();
      }
      // si on est sur le serveur wivy ET qu'on a acces a la geolocalisation
      else if (navigator.geolocation) {
        // on lance le test de geolocalisation
        navigator.geolocation.getCurrentPosition(this.apresRetourPositionGeolocalisation, this.apresErreurGeolocalisation);
      } else {
        this.messageErreur = "La géolocalisation n'est pas autorisée sur votre navigateur.";
      }
    },
    // si on a reussi a recuperer un position apres le test de geolocalisation
    apresRetourPositionGeolocalisation(position : any) {
      this.latitude = position.coords.latitude;
      this.longitude = position.coords.longitude;
      console.log("Device : Lat = "+this.latitude.toString()+" / Long = "+this.longitude.toString());
      console.log("Etablissement : Lat = "+this.localEtablissement.latitude.toString()+" / Long = "+this.localEtablissement.longitude.toString());
      this.messageErreur = '';
      let distanceEntreDeviceEtEtablissement = calculateDistance(this.latitude, this.longitude, this.localEtablissement.latitude, this.localEtablissement.longitude);
      // si la position du device est proche de la position de l'etablissement (moins de 3KM)
      if(distanceEntreDeviceEtEtablissement < LIMIT_DISTANCE_DEVICE_ETABLISSEMENT){
        // on lance la creation d'une nouvelle session
        this.creationNouvelleSession();
      }
      else {
        this.messageErreur = "La position de votre appareil n'est pas dans l'établissement. ("+distanceEntreDeviceEtEtablissement.toString()+"m)";
      }
    },
    // si erreur apres le test de geolocalisation
    apresErreurGeolocalisation(error : any) {
      if (error.code === error.PERMISSION_DENIED) {
        this.messageErreur = "Vous devez autoriser l'accès à la position sur votre appareil.";
      } else {
        this.messageErreur = `ERROR(${error.code}): ${error.message}`;
      }
    },
    // chargement de la quesrtion suivante
    creationNouvelleSession(){
      console.log("Creation nouvelle session");

      // creation de la nouvelle session
      this.sessionActuelle = initSession(typeJeuxChoisi, this.localEtablissement.ID);

      // chargement de la premiere question
      this.chargementQuestionSuivante();
    }, 
    chargementQuestionSuivante(){
      // on met le popup chargement de question
      this.chargementQuestionSuivanteEnCours = true;
      this.questionAafficher = '';
      console.log("Chargement question suivante");

      // on recupere une question au hasard dans la BDD
      lectureObjetQuestionSuivante(this.sessionActuelle).then(data => {
        // si on a reussi a recuperer la question
        if(data != ''){
          // on lance la question
          this.clicQuestion(data);
        }
        // si on n'a pas reussi a recuperer la question dans la BDD
        else{
          // message erreur
          console.log("Impossible de charger la question suivante");
          this.messageErreur = "Impossible de charger la question suivante. Veuillez recharger la page.";
        }
      });
    },
    clicRechargerLaPage() {
      window.location.reload();
    }
},
// sur la creation de la page
created() {
  this.chargementEtablissementEnCours = true;
  // on initialise le serveur avec les bons liens
  initServeur(ConstServeur.SERVEUR_WIVYF);    
  
  // on check l'etablissement a partir de l'url
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const idText = urlParams.get('e'); //?e=20888

  if(idText != null){
    var idEtablissement = parseInt(idText, 10); // Convert string to integer

    // si l'id de l'etablissement est null
    if (isNaN(idEtablissement)) {
      this.messageErreur = "Impossible de recuperer l'id de l'établissement. Essayez de recharger la page.";
    }
    else{
      
// on va chercher la question dans la BDD avec son ID
      lectureObjetBDDVsID(idEtablissement.toString(), ConstServeur.NOM_TABLE_ETABLISSEMENT).then(data => {
        // si on a reussi a recuperer la question
        if(data != '') {
          // on affiche l'etablissement a l'ecran pour demander la localisations
          this.localEtablissement = JSON.parse(data);
          this.accesClient = this.localEtablissement.facturation.accesWivyFamille;
          console.log("Chargement etablissement ok : " + this.localEtablissement.raisonSocial);
        }
        // si on n'a pas reussi a recuperer l'etablissement
        else{
          // message erreur
          this.messageErreur = "Impossible de charger l'établissement "+idEtablissement.toString()+". Essayez de recharger la page.";
        }
      });
    }
  }
  else {
    this.messageErreur = "Impossible de recuperer l'id de l'établissement. Essayez de recharger la page.";
  }

  this.chargementEtablissementEnCours = false;

}

});

</script>

<style>
@font-face {
  font-family: 'QuicksandBold';
  src: url('../public/fonts/Quicksand_Bold.otf') format('opentype');
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  overflow: auto;
}

.boutonAfficherQuestion{
  box-sizing: border-box;
    width: 10em;  /* 100% of the viewport width */
    height: 6em;  /* 50% of the viewport height */
    overflow: auto; 
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    position: relative;
    float : left; 
    bottom: 0;
    left: 0;
    margin: 2%;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: #2F3E53; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px; /* Top-left, top-right, bottom-right, bottom-left */
    /* attention fit par rapport a texte ici*/
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}

.textePleinEcran {
  box-sizing: border-box;
  width: 100%;  /* 100% of the viewport width */
  height: 100%;  /* 50% of the viewport height */
  padding: 10%;
  position: fixed;
  /* met le bloc a la suite du bloc precedant */
  float : left; 
  bottom: 0;
  left: 0;
  margin: 0;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* texte blanc */
  color: #2F3E53;
  /* fond bleu */
  background-color: white; /* Color of the rectangle */
  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;
}

.ensembleAfficheurEtablissement {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 5%;
  position: fixed;
  /* met le bloc a la suite du bloc precedant */
  float : left; 
  bottom: 0;
  left: 0;
  margin: 0;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* texte blanc */
  color: #2F3E53;
  /* fond bleu */
  background-color: whitesmoke; /* Color of the rectangle */
}

.nomDeLetablissement { 
  box-sizing: border-box;
  width: 100%;
  height: 30%;
  padding: 5%;
  position: relative;
  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: calc(2vw + 2vh + 2vmin);
}

.partieEtablissement {
  box-sizing: border-box;
  width: 100%;
  height: 70%;
  padding: 5%;
  position: relative;
}

.partieTexteClient {
  box-sizing: border-box;
  width: 100%;
  height: 50%;
  padding: 5%;
  position: relative;
}

.boutonCommencer{
  box-sizing: border-box;
    width: 50%;  /* 100% of the viewport width */
    height: 25%;  /* 50% of the viewport height */
    min-width: 250px; 
    padding: 0;
    /* met le bloc a la suite du bloc precedant */
    bottom: 0;
    left: 0;
    margin: 0 auto;
    margin-top: 5%;
    /* typo quicksand bold */
    font-family: 'QuicksandBold';
    /* texte blanc */
    color: white;
    /* fond bleu */
    background-color: #2F3E53; /* Color of the rectangle */
    /* bord arrondi */
    border-radius: 25px;
    /* centre le texte horizontallement */
    text-align: center;
    /* centre le texte verticalement */
    display: flex;
    align-items: center;
    justify-content: center;
}

/*************************************
****** AFFICHAGE MESSAGE ERREUR ******
**************************************/
.ensembleMessageErreur {
box-sizing: border-box;
  width: 100%;  /* 100% of the viewport width */
  height: 100%;  /* 50% of the viewport height */
  padding: 10%;
  position: fixed;
  /* met le bloc a la suite du bloc precedant */
  float : left; 
  bottom: 0;
  left: 0;
  margin: 0;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* fond bleu */
  background-color: #2F3E53; /* Color of the rectangle */
}

.texteMessageErreur {
  box-sizing: border-box;
  width: 100%;  /* 100% of the viewport width */
  height: 70%;  /* 50% of the viewport height */
  padding: 10%;
  position: relative;
  /* met le bloc a la suite du bloc precedant */
  float : left; 
  /* texte blanc */
  color: white;
  /* fond bleu */
  background-color: #2F3E53; /* Color of the rectangle */
  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;
}

.boutonRechargerLaPage {
  box-sizing: border-box;
  width: 50%;  /* 100% of the viewport width */
  height: 30%;  /* 50% of the viewport height */
  min-width: 250px; 
  padding: 0;
  /* met le bloc a la suite du bloc precedant */
  bottom: 0;
  left: 0;
  margin: 0 auto;
  margin-top: 5%;
  /* typo quicksand bold */
  font-family: 'QuicksandBold';
  /* texte blanc */
  color: #2F3E53;
  /* fond bleu */
  background-color: white; /* Color of the rectangle */
  /* bord arrondi */
  border-radius: 25px;
  /* centre le texte horizontallement */
  text-align: center;
  /* centre le texte verticalement */
  display: flex;
  align-items: center;
  justify-content: center;

}



</style>
