// store.js
import { reactive } from 'vue';

export const InteractionAfficheurImagePleinEcran = reactive({
  urlImage: '' as string,
  legende: '' as string
});


export function retirerImageEnGrand() {
    InteractionAfficheurImagePleinEcran.urlImage = '';
    InteractionAfficheurImagePleinEcran.legende = '';
}

export function afficherImageEnGrand(inUrlImage : string, inLegende : string) {
    InteractionAfficheurImagePleinEcran.urlImage = inUrlImage;
    InteractionAfficheurImagePleinEcran.legende = inLegende;
}